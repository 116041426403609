<template>
	<div class="external-links">
		<a
			class="external-link"
			:class="link.site"
			:href="link.url"
			v-for="link in externalLinks"
			:key="link.url"
			:alt="link.site"
			target="_blank"
			rel="noopener noreferrer"
		>
			<span class="visually-hidden">{{ link.site }}</span>
			<icon
				class="icon"
				:name="link.site === 'Official Site' ? 'compass' : link.site"
				width="14"
				color="#fff"
				:fill="link.site !== 'Official Site'"
			/>
		</a>
	</div>
</template>

<script>
export default {
	props: {
		media: {
			type: Object,
			required: true
		}
	},
	computed: {
		externalLinks() {
			const links = this.media.externalLinks;

			if (links.length > 4) {
				return links
					.filter(link => link.site !== "Official Site" && link.site !== "Twitter")
					.slice(0, 4);
			}

			return links;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.external-link {
	align-content: center;
	background: #515381;
	border-radius: 3px;
	display: inline-flex;
	height: 23px;
	justify-content: center;
	margin-right: 7px;
	width: 23px;

	.icon {
		color: color(white);
		margin-right: 0;
	}
}
</style>
