<template>
	<a class="cover" :href="url" target="_blank" rel="noopener noreferrer">
		<div class="background-color" />
		<img
			class="media-image"
			:src="media.coverImage.extraLarge"
			:class="{ loaded: imageLoaded }"
			@load="onImageLoad"
		/>
		<div class="overlay">
			<a class="title" :href="url" target="_blank">{{ title }}</a>
			<div class="studio" v-if="media.studios && media.studios.nodes[0]">
				<template v-for="(studio, key) in studios">
					<a :href="studio.siteUrl" target="_blank" rel="noopener noreferrer" :key="studio.id">{{
						studio.name
					}}</a
					><template v-if="media.studios.nodes[key + 1]"
						>,
					</template>
				</template>
			</div>
		</div>
	</a>
</template>

<script>
import Settings from "@/util/settings";

export default {
	props: {
		media: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			imageLoaded: false
		};
	},
	computed: {
		url() {
			return Settings.getMediaUrl(this.media);
		},
		title() {
			return Settings.getMediaTitle(this.media);
		},
		studios() {
			return this.media.studios.nodes.map(studioId =>
				this.$store.getters.entity("studio", studioId)
			);
		}
	},
	methods: {
		onImageLoad() {
			this.imageLoaded = true;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.cover {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	position: relative;
	z-index: 10;

	.media-image,
	.background-color {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
	}

	.media-image {
		object-fit: cover;
		object-position: center;
		opacity: 0;
		transition: opacity 300ms;

		&.loaded {
			opacity: 1;
		}
	}

	.background-color {
		background-color: var(--media-color);
	}

	.media-image,
	.background-color {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.background-color {
		background-color: var(--media-color);
	}

	.overlay {
		background: color(overlay, 0.9);
		color: color(text-bright);
		font-size: 1.4rem;
		padding: 12px;
		position: relative;
		width: 100%;
		font-weight: 600;

		a {
			position: relative;
			z-index: 10;

			&:hover {
				color: var(--media-overlay-text-color);
			}
		}

		&:before {
			background: var(--media-color);
			content: "";
			height: 100%;
			left: 0;
			opacity: 0.05;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 3;
		}
	}
}

a.title {
	color: color(white);
}

.studio {
	color: var(--media-overlay-text-color);
	font-size: 1.2rem;
	margin-top: 8px;
}
</style>
