<template>
	<div class="dialog dropdown-menu share-chart">
		<h2 class="title">Share</h2>
		<div class="description">Click below to share this chart with your color highlights!</div>
		<div class="link-copied" :class="{ active: linkCopied }">Link copied to clipboard</div>
		<div class="select">
			<div class="option" @click="copyUrl">
				<icon name="link" color="currentColor" width="16px" :fill="true" />
			</div>
			<div class="option" @click="tweet">
				<icon name="Twitter" color="currentColor" width="18px" :fill="true" />
			</div>
			<div class="option" @click="facebookPost">
				<icon name="Facebook" color="currentColor" height="18px" :fill="true" />
			</div>
		</div>
	</div>
</template>

<script>
import copy from "clipboard-copy";

export default {
	props: ["highlights"],
	data() {
		return {
			linkCopied: false
		};
	},
	computed: {
		shareUrl() {
			let url = `https://anichart.net${this.$route.path}`;
			if (this.highlights) {
				url += `?shared=1${this.highlights}`;
			}

			return url;
		}
	},
	methods: {
		async copyUrl() {
			await copy(this.shareUrl);
			this.linkCopied = true;
			setTimeout(() => {
				this.linkCopied = false;
			}, 2500);
		},
		tweet() {
			const url = encodeURIComponent(this.shareUrl);
			const [season, year] = this.$route.path.replace("/", "").split("-");
			const tweet = encodeURIComponent(
				`Check out what I'm watching in the ${season} ${year} #anime season`
			);
			window.open(
				`https://twitter.com/intent/tweet?text=${tweet}&hashtags=AniChart&url=${url}`,
				"_blank"
			);
		},
		facebookPost() {
			const url = encodeURIComponent(this.shareUrl);
			window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}`, "_blank");
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.share-chart {
	margin-top: 14px;
	padding: 18px;
	padding-top: 14px;
	right: 20px;
	width: 220px;

	.select {
		grid-template-columns: repeat(3, 1fr);
	}

	.link-copied {
		bottom: 70px;
		left: 30px;
	}
}
</style>
