<template>
	<div class="genres">
		<div class="genre" v-for="(genre, key) in genres" :key="key">{{ genre }}</div>
	</div>
</template>

<script>
export default {
	props: {
		genres: {
			type: Array
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.genres {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	height: 20px;
	overflow: hidden;
}

.genre {
	background: var(--media-color);
	border-radius: 10px;
	color: var(--media-color-text);
	display: inline-block;
	font-size: 1.2rem;
	font-weight: 700;
	height: 20px;
	line-height: 2rem;
	margin-right: 11px;
	padding: 0 12px;
	text-transform: lowercase;

	@media (max-width: $size-tablet) {
		line-height: 2.1rem;
	}
}
</style>
