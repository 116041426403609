<template>
	<div class="description-wrap">
		<span class="description" v-html="description.truncated" />
		<transition name="fade">
			<span
				key="remainingDescription"
				class="remaining-description"
				v-if="showFullDescription"
				v-html="description.remaining"
			/>
			<span key="ellipses" class="ellipses" v-else-if="description.showEllipses">…</span>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		media: {
			type: Object,
			required: true
		},
		showFullDescription: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		descriptionLength() {
			if (this.$store.state.imageChart) {
				return Infinity;
			}

			const width = this.$store.state.screen.width;
			const sizes = [
				{ width: 1470, length: 270 },
				{ width: 1345, length: 240 },
				{ width: 1345, length: 300 },
				{ width: 1050, length: 250 },
				{ width: 900, length: 420 },
				{ width: 800, length: 350 },
				{ width: 700, length: 600 },
				{ width: 600, length: 400 },
				{ width: 410, length: 230 },
				{ width: 370, length: 150 },
				{ width: 0, length: 120 }
			];
			return sizes.find(size => width >= size.width).length;
		},
		description() {
			let { description } = this.media;
			if (!description || description === "") return {};

			// Remove <br> tags and source
			description = description.replace(/((<br ?\/?>)|(\n)|(\(S?ource: ?.+\)))/gim, " ");

			// Truncate description for when not hovering over the card
			let truncated = description.slice(0, this.descriptionLength);

			let showEllipses = false;
			if (description.length > this.descriptionLength) {
				showEllipses = true;
				truncated = truncated.slice(0, truncated.lastIndexOf(" "));
			}

			// Don't truncate description at a symbol
			const dontEndWith = [",", ":"];
			const lastChar = truncated.slice(-1);
			if (dontEndWith.includes(lastChar)) {
				truncated = truncated.slice(0, -1);
			}

			return {
				truncated,
				showEllipses,
				remaining: description.slice(truncated.length)
			};
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.description-wrap {
	color: color(text-light);
	font-size: 1.1rem;
	line-height: 1.6;
	margin-top: 6px;
	transition: color 200ms;

	@media (max-width: $size-tablet) {
		line-height: 1.6rem;
	}

	@media (max-width: $size-mobile) {
		margin-top: 0;
	}
}

.ellipses {
	position: absolute;

	// Fade in the ellipses only after the remaining description has faded away
	&.fade-enter-to {
		transition-delay: 150ms;
	}
}
</style>
