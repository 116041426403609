<template>
	<a class="hashtag" :href="hashtagUrl" target="_blank" rel="noopener noreferrer" v-if="hashtag">
		<icon class="icon" name="hash" width="18" /> <span>{{ hashtag }}</span>
	</a>
</template>

<script>
export default {
	props: {
		media: {
			type: Object,
			required: true
		}
	},
	computed: {
		hashtag() {
			const hashtags = this.media.hashtag.replace("#", "").split(" ");
			return hashtags[0];
		},
		hashtagUrl() {
			const hashtag = encodeURIComponent(this.media.hashtag).replace(/%20/g, "+OR ");
			return `https://twitter.com/search?q=${hashtag}`;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.hashtag {
	align-items: center;
	display: flex;
	font-weight: 700;
	margin-bottom: 8px;
	overflow: hidden;
	white-space: nowrap;
	width: 100%;

	span {
		overflow: hidden;
	}
}
</style>
