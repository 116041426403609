<template>
	<div
		class="media-card"
		@mouseover="mouseover"
		@mouseleave="mouseleave"
		:style="colors"
		v-show="show"
		v-if="!media.isAdult"
	>
		<cover :media="media" />
		<div class="data">
			<div class="scroller" ref="scroller">
				<div class="scroller-wrap" ref="scrollerWrap">
					<div
						class="body"
						:class="{ 'reveal-external-links': externalLinksVisible || trailerActive }"
					>
						<div class="header">
							<div><airing :media="media" /> <relation :media="media" /></div>
							<stats :media="media" />
						</div>
						<div class="external-links-wrap">
							<div class="section">
								<hashtag :media="media" v-if="media.hashtag" />
								<external-links :media="media" />
							</div>
							<trailer :media="media" :active.sync="thisTrailerActive" v-if="media.trailer" />
						</div>
						<description :media="media" :show-full-description="externalLinksVisible" />
					</div>
				</div>
			</div>

			<div class="footer">
				<genres :genres="media.genres" /> <highlighter :id="id" @hide-card="hide" />
			</div>
		</div>
	</div>
</template>

<script>
import { pluralize, sleep, getMonth } from "@/util";
import getColors from "@/util/cardColor";

import Cover from "./card/Cover";
import Airing from "./card/Airing";
import Relation from "./card/Relation";
import Stats from "./card/Stats";
import Hashtag from "./card/Hashtag";
import Trailer from "./card/Trailer";
import ExternalLinks from "./card/ExternalLinks";
import Description from "./card/Description";
import Genres from "./card/Genres";
import Highlighter from "./Highlighter";

const animationDuration = {
	hoverIntent: 150,
	slide: 400
};

export default {
	props: {
		id: {
			type: Number,
			required: true
		},
		loadBannerImage: {
			type: Boolean,
			default: false
		}
	},
	components: {
		Cover,
		Airing,
		Relation,
		Stats,
		Hashtag,
		Trailer,
		ExternalLinks,
		Genres,
		Description,
		Highlighter
	},
	data() {
		return {
			bannerLoaded: false,
			hovering: false,
			externalLinksVisible: false,
			thisTrailerActive: false,
			isVueBarActive: false,
			show: true
		};
	},
	computed: {
		media() {
			return this.$store.getters.entity("media", this.id);
		},
		colors() {
			return getColors(this.media.coverImage.color);
		},
		trailerActive() {
			return this.thisTrailerActive && this.$store.state.trailer.active === this.media.id;
		}
	},
	methods: {
		async mouseover(event) {
			const showBanner = await this.showExternalLinks();
			if (showBanner) {
				this.setNavBanner(event, true);
			}
		},
		async mouseleave(event) {
			await this.scrollToTop();
			await this.hideExternalLinks();
			this.setNavBanner(event);
		},
		showExternalLinks() {
			if (this.hovering) return false;

			return new Promise(async resolve => {
				this.hovering = true;
				await sleep(animationDuration.hoverIntent);
				if (this.hovering) {
					this.enableVueBar();
					this.externalLinksVisible = true;

					// Wait for animation to finish
					await sleep(animationDuration.slide);
					resolve(true);
				} else {
					resolve(false);
				}
			});
		},
		hideExternalLinks() {
			let response = false;
			if (this.showExternalLinks && this.hovering) {
				response = new Promise(async resolve => {
					// Wait for animation to finish
					await sleep(animationDuration.slide);
					resolve();
				});
			}
			this.externalLinksVisible = false;
			this.hovering = false;
			return response;
		},
		enableVueBar() {
			if (this.isVueBarActive) return;

			const { scroller } = this.$refs;
			this.$vuebar.initScrollbar(scroller, { resizeRefresh: false });
			this.isVueBarActive = true;
		},
		scrollToTop() {
			return new Promise(resolve => {
				const scrollerWrap = this.$refs.scrollerWrap;
				const scrollSpeed = 6;
				const doScroll = () => {
					const scroll = scrollerWrap.scrollTop;
					if (scroll > 0) {
						window.requestAnimationFrame(doScroll);
						scrollerWrap.scrollTo(0, scroll - scroll / scrollSpeed);
					} else {
						resolve();
					}
				};
				doScroll();
			});
		},
		hide() {
			this.show = false;
		},
		setNavBanner(event, active = false) {
			this.$store.commit("setActiveBannerImage", active ? this.media.id : null);
		},
		initBannerImage() {
			if (
				!this.bannerLoaded &&
				this.loadBannerImage &&
				this.media.bannerImage &&
				this.media.format === "TV"
			) {
				this.bannerLoaded = true;
				this.$store.dispatch("addBannerImage", {
					id: this.media.id,
					url: this.media.bannerImage
				});
			}
		}
	},
	created() {
		this.initBannerImage();
	},
	beforeDestroy() {
		if (this.isVueBarActive) {
			const { scroller } = this.$refs;
			this.$vuebar.destroyScrollbar(scroller);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.media-card {
	backface-visibility: hidden;
	background: color(foreground);
	border-radius: 3px;
	box-shadow: 0 4px 6px shadow(shadow, 0.05), 0 5px 20px shadow(shadow, 0.08);
	display: inline-grid;
	grid-template-columns: 185px auto;
	height: 265px;
	min-width: 370px;
	overflow: hidden;
	position: relative;
	text-align: left;
	transition: box-shadow 0.6s ease;

	&:hover {
		box-shadow: 0 4px 6px shadow(shadow, 0.09), 0 10px 40px shadow(shadow, 0.3);
	}

	@media (max-width: $size-tablet) {
		grid-template-columns: 175px auto;
		min-width: 330px;
		width: 100%;
	}

	@media (max-width: $size-mobile) {
		grid-template-columns: 150px auto;
		height: 230px;
		min-width: 320px;
	}
}

.data {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto 44px;
	min-height: 0;
	min-width: 0;
}

.body,
.external-links-wrap {
	padding: 17px;
}

.body {
	display: flex;
	flex-direction: column;
	min-height: 0;
	padding-bottom: 10px;
	position: relative;

	.header {
		display: grid;
		grid-template-columns: auto 60px;
		opacity: 1;
		transition: transform 400ms ease-in-out, opacity 250ms ease-in-out;
		z-index: 2;

		> div {
			min-width: 0;
		}

		@media (max-width: $size-tablet) {
			grid-template-columns: auto;

			.icon-stats {
				display: none;
			}
		}
	}

	@media (max-width: $size-tablet) {
		.header,
		.external-links-wrap {
			grid-template-columns: auto;

			.icon-stats,
			.trailer {
				display: none;
			}
		}
	}
}

.scroller {
	min-height: 0;
	overflow: hidden;

	// Work around for double scroll bar issue on webkit/blink
	// https://github.com/DominikSerafin/vuebar/issues/55
	::-webkit-scrollbar {
		display: none;
	}
}

.reveal-external-links {
	.header {
		opacity: 0;
		transform: translateX(-100%);
	}

	.external-links-wrap {
		opacity: 1;
		transform: translateX(0);
	}

	&.body {
		padding-bottom: 5px;
	}

	.description-wrap {
		color: color(text);
	}
}

.external-links-wrap {
	display: grid;
	grid-template-columns: auto 75px;
	height: 7.6rem;
	left: 0;
	opacity: 0;
	position: absolute;
	top: 0;
	transform: translateX(100%);
	transition: transform 400ms ease-in-out, opacity 250ms ease-in-out;
	width: 100%;

	.icon {
		margin-right: 3px;
	}

	.section {
		max-width: 90%;
		min-width: 90%;
	}
}

.footer {
	background: color(foreground-blue);
	display: flex;
	align-items: center;
	padding: 0 17px;
	padding-right: 14px;

	@media (max-width: $size-tablet) {
		padding: 0 12px;
		padding-right: 8px;
	}
}

.highlighter {
	margin-left: auto;
}
</style>
