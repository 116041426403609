import { convert } from "chromatism";
import { defaultColors, colorPalette } from "./colorPalette";

function getClosestColor(baseColor, colors) {
	baseColor = convert(baseColor).rgb;
	let bestMatch,
		minDistance = Infinity;

	colors.forEach(color => {
		const rgb = color.background;
		let distance =
			Math.pow(baseColor.r - rgb.r, 2) +
			Math.pow(baseColor.g - rgb.g, 2) +
			Math.pow(baseColor.b - rgb.b, 2);

		if (distance < minDistance) {
			minDistance = distance;
			bestMatch = color;
		}
	});

	return {
		background: convert(bestMatch.background),
		text: convert(bestMatch.text)
	};
}

export default function getColors(baseColor) {
	if (!baseColor) return defaultColors;

	const color = getClosestColor(baseColor, colorPalette);
	const textColor = color.text.hsl;
	const overlayTextColor = {
		h: textColor.h,
		l: 70,
		s: 80
	};

	return {
		"--media-color": color.background.csshsl,
		"--media-color-text": color.text.csshsl,
		"--media-overlay-text-color": convert(overlayTextColor).csshsl
	};
}
