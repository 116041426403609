import store from "../store";
import Settings from "@/util/settings";

const media = id => store.getters.entity("media", id);
const studio = id => store.getters.entity("studio", id);
const getTime = () => store.getters.getTime;
const getDateValue = date => {
	let month = date.month || 0;
	let day = date.day || 0;
	if (month < 10) month = `0${month}`;
	if (day < 10) day = `0${day}`;
	return parseInt(`${date.year || 0}${month}${day}`);
};

export default {
	dateAdded: {
		direction: "desc",
		sorter: id => id
	},
	popularity: {
		direction: "desc",
		sorter: id => {
			const popularity = media(id).popularity;
			return popularity || 0;
		}
	},
	averageScore: {
		direction: "desc",
		sorter: id => {
			const score = media(id).averageScore;
			return score || 0;
		}
	},
	title: {
		direction: "asc",
		sorter: id => {
			return Settings.getMediaTitle(media(id)).toLowerCase();
		}
	},
	studio: {
		direction: "asc",
		sorter: id => {
			const studios = media(id).studios;
			if (!studios || !studios.nodes[0]) {
				return null;
			}

			return studio(studios.nodes[0]).name.toLowerCase();
		}
	},
	nextAiring: {
		direction: "asc",
		sorter: id => {
			const nextEpisode = media(id).airingSchedule.nodes.find(node => node.airingAt > getTime());
			if (!nextEpisode) {
				return Infinity;
			}

			return nextEpisode.airingAt - getTime();
		}
	},
	startDate: {
		direction: "asc",
		sorter: id => getDateValue(media(id).startDate)
	},
	endDate: {
		direction: "asc",
		sorter: id => getDateValue(media(id).endDate)
	}
};
