<template>
	<div>
		<div v-if="prequel" class="source">Sequel to {{ prequel }}</div>
		<div v-else-if="media.source" class="source">Source • {{ media.source | capitalize }}</div>
	</div>
</template>

<script>
import Settings from "@/util/settings";

export default {
	props: {
		media: {
			type: Object,
			required: true
		}
	},
	computed: {
		prequel() {
			const relationEdge = this.media.relations.edges.find(edge => edge.relationType === "PREQUEL");
			if (!relationEdge) {
				return null;
			}

			const media = this.$store.getters.entity("media", relationEdge.node);
			if (!media) {
				return null;
			}

			return Settings.getMediaTitle(media);
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.source {
	display: inline-block;
	font-size: 1.1rem;
	overflow: hidden;
	padding-top: 3px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}
</style>
