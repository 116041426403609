<template>
	<div class="sense-wrap" v-if="show">
		<Adsense :data-ad-client="client" :data-ad-slot="slot" />
	</div>
</template>

<script>
export default {
	data() {
		return {
			client: "ca-pub-2100847942938873",
			slot: "5727534245"
		};
	},
	computed: {
		show() {
			if (process.env.NODE_ENV !== "production") {
				return false;
			}

			const user = this.$store.state.auth.user;
			if (!user.id) {
				return true;
			}

			return user.donatorTier < 2;
		}
	}
};
</script>

<style lang="scss" scoped>
.sense-wrap {
	margin-top: 60px;
	max-height: 140px;
	overflow: hidden;
	text-align: center;
	width: 100%;
}
</style>
