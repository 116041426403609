<template>
	<div
		class="trailer"
		:style="`background-image: url('${media.trailer.thumbnail}')`"
		@click="showTrailer"
		ref="trailer"
	>
		<icon class="icon" name="play-circle" width="24" />
	</div>
</template>

<script>
export default {
	props: {
		media: {
			type: Object,
			required: true
		},
		active: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		showTrailer() {
			const trailerElement = this.$refs.trailer;
			const { left, top } = trailerElement.getClientRects()[0];
			this.$store.commit("setTrailer", {
				active: this.media.id,
				trailer: this.media.trailer,
				position: {
					top,
					left
				}
			});
			this.$emit("update:active", true);
		}
	}
};
</script>

<style lang="scss">
@import "~@/styles/variables.scss";

.trailer {
	align-items: center;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 3px;
	cursor: pointer;
	display: flex;
	height: 42px;
	justify-content: center;
	overflow: hidden;
	position: relative;
	width: 75px;

	&:before {
		background: color(overlay, 0.5);
		content: "";
		height: 100%;
		position: absolute;
		width: 100%;
	}

	.icon {
		color: color(white, 0.9);
		position: relative;
	}
}
</style>
