<template>
	<div class="chart-view">
		<mobile-filters v-if="screen.isMobile" />
		<transition-group name="list" tag="div" @after-enter="afterEnter">
			<section
				v-show="chartSection.length || (sectionIndex === 0 && chartLoaded)"
				class="section"
				v-for="(chartSection, format, sectionIndex) in visibleChart"
				:key="format"
				:class="{ loaded: chartLoaded }"
			>
				<h2 class="section-heading">
					{{ format | capitalize }}
					<div />
					<div class="uhd" />
					<filters v-if="sectionIndex === 0 && !screen.isMobile" />
				</h2>
				<div class="card-list" v-if="!emptyChart">
					<card
						v-for="(mediaId, index) in chartSection"
						:key="mediaId"
						:id="mediaId"
						:load-banner-image="index < 12"
					/>
				</div>
				<div class="empty-chart" v-else>{{ emptyChart }}</div>
				<ad v-if="sectionIndex === 0 && chartLoaded && !emptyChart" />

				<mugen-scroll
					v-if="chartLoaded"
					:handler="renderCards(format, sectionIndex)"
					key="mugen-cards"
				/>
			</section>
		</transition-group>

		<section class="section placeholder loading" :class="{ loaded: chartLoaded }">
			<h2 class="section-heading">Loading</h2>
			<div class="card-list"><loading-card v-for="n in loadingCardCount" :key="n" /></div>
		</section>

		<div
			class="shared-chart-notice"
			v-if="isSharedChart && !isImageChart"
			@click="returnToNonSharedChart"
		>
			Viewing shared chart
			<div>Click here to show your own highlights</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex";
import MugenScroll from "vue-mugen-scroll";
import _ from "lodash";

import Card from "@/components/Card";
import LoadingCard from "@/components/LoadingCard";
import Filters from "@/components/Filters";
import MobileFilters from "@/components/MobileFilters";
import Ad from "@/components/Ad";

import Settings from "@/util/settings";
import sorts from "@/util/sorts";
import { capitalize } from "@/util";

const defaultCardRenderAmount = 15;

export default {
	name: "Chart",
	components: {
		Card,
		LoadingCard,
		Filters,
		MobileFilters,
		Ad,
		MugenScroll
	},
	data() {
		return {
			chartLoaded: false,
			visible: [defaultCardRenderAmount]
		};
	},
	computed: {
		...mapGetters(["isImageChart", "isSharedChart"]),
		...mapState(["sort", "filter", "screen"]),
		filteredChart() {
			if (!this.filter || this.filter === "") {
				return this.chart;
			}

			return this.chart.filter(id => {
				const media = this.$store.getters.entity("media", id);
				const title = Settings.getMediaTitle(media).toLowerCase();
				return title.includes(this.filter.toLowerCase());
			});
		},
		sortedChart() {
			const { sorter, direction } = sorts[this.sort];
			const secondaySort = sorts["title"];

			return _.orderBy(
				this.filteredChart,
				[sorter, secondaySort.sorter],
				[direction, secondaySort.direction]
			);
		},
		chartByFormat() {
			const specials = ["OVA", "ONA", "SPECIAL"];
			const formatGroups = {
				TV: [],
				TV_SHORT: [],
				LEFTOVERS: [],
				MOVIE: [],
				"OVA / ONA / Special": []
			};
			const groupByFormat = (formats, id) => {
				let { format, season } = this.$store.getters.entity("media", id);

				// Put all specials beneath the same header
				if (specials.includes(format)) {
					format = "OVA / ONA / Special";
				} else if (this.season && season.toLowerCase() !== this.season.toLowerCase()) {
					format = "LEFTOVERS";
				}

				if (!formats[format]) {
					formats[format] = [];
				}
				formats[format].push(id);
				return formats;
			};

			return this.sortedChart.reduce(groupByFormat, formatGroups);
		},
		visibleChart() {
			const charts = this.chartByFormat;
			if (this.isImageChart) {
				return charts;
			}

			const visibleCharts = {};
			Object.keys(charts).forEach((section, index) => {
				if (this.visible[index]) {
					visibleCharts[section] = charts[section].slice(0, this.visible[index]);
				}
			});

			return visibleCharts;
		},
		emptyChart() {
			if (!this.chartLoaded) return false;
			const charts = this.visibleChart;
			const hasEntries = Object.keys(charts).find(section => charts[section].length > 0);
			if (hasEntries) return false;

			if (this.filter) {
				return "No anime found with this filter";
			}

			const { season, year } = this.$route.params;
			return `No anime have been announced yet for ${season} ${year}`;
		},
		loadingCardCount() {
			const width = this.screen.width;
			const sizes = [
				{ width: 2250, cards: 16 },
				{ width: 1470, cards: 12 },
				{ width: 800, cards: 8 },
				{ width: 0, cards: 4 }
			];
			return sizes.find(size => width >= size.width).cards;
		}
	},
	beforeMount() {
		this.$store.commit("setSharedChart", !!this.$route.query.shared);
		this.$store.commit("setImageChart", !!this.$route.query.image);
	},
	beforeRouteUpdate(to, from, next) {
		this.chartLoaded = false;
		this.visible = [defaultCardRenderAmount];
		this.$store.commit("setSharedChart", !!to.query.shared);
		this.$store.commit("setFilter", null);
		next();
	},
	methods: {
		renderCards(section, index) {
			return () => {
				const visibleCardAmount = this.visible[index] || 0;

				if (this.chartByFormat[section].length > visibleCardAmount) {
					this.$set(this.visible, index, visibleCardAmount + 15);
				} else if (!this.visible[index + 1]) {
					// Show next section if we've already shown all the cards for this section
					this.visible.push(defaultCardRenderAmount);
				}
			};
		},
		async fetchChart(fetchType, ...args) {
			await this[`fetch${fetchType}`](...args);

			// Sometimes afterEnter doesn't get called by vue
			// so call it after the fetch is complete just in case
			this.afterEnter();
		},
		afterEnter() {
			this.chartLoaded = true;
		},
		returnToNonSharedChart() {
			this.$store.commit("setSharedChart", false);
			this.$router.push({
				name: "season",
				params: {
					season: this.$route.params.season,
					year: this.$route.params.year
				}
			});
			window.location.reload();
		}
	},
	metaInfo() {
		const { season, year } = this.$route.params;
		const capitalizedSeason = capitalize(season);
		const title = `AniChart: ${capitalizedSeason} ${year} Seasonal Chart`;
		const description = `Find, track, and share what's airing during the ${capitalizedSeason} ${year} anime season on AniChart. Find the top-rated and most popular shows, OVAs, and movies!`;
		return {
			title,
			meta: [
				{
					name: "description",
					content: description
				},
				{
					property: "og:title",
					content: title
				},
				{
					property: "og:description",
					content: description
				},
				{
					property: "og:url",
					content: `https://anichart.net${this.$route.path}`
				},
				{
					property: "twitter:title",
					content: title
				},
				{
					property: "twitter:description",
					content: description
				}
			]
		};
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.chart-view {
	margin: 0 5vw;
	position: relative;

	@media (max-width: $size-desktop-hd) {
		margin: 0 1vw;
	}

	@media (max-width: $size-desktop) {
		margin: 0 10vw;
	}

	@media (max-width: 1130px) {
		margin: 0 5vw;
	}

	@media (max-width: 1050px) {
		margin: 0 15vw;
	}

	@media (max-width: $size-tablet) {
		margin: 0 10px;
		padding-bottom: 80px;
	}
}

.card-list {
	display: grid;
	grid-column-gap: 50px;
	grid-row-gap: 40px;
	grid-template-columns: repeat(3, minmax(390px, 460px));
	grid-template-rows: repeat(auto-fill, 265px);
	justify-content: center;
	min-width: 0;
	width: 100%;

	@media (min-width: $size-desktop-uhd) {
		grid-template-columns: repeat(4, minmax(390px, 460px));
	}

	@media (max-width: $size-desktop-hd) {
		grid-column-gap: 20px;
		grid-row-gap: 20px;
	}

	@media (max-width: $size-desktop) {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 40px;
		grid-row-gap: 40px;
	}

	@media (max-width: 1050px) {
		grid-template-columns: 1fr;
	}

	@media (max-width: $size-mobile) {
		grid-template-rows: repeat(auto-fill, 230px);
		grid-row-gap: 25px;
	}
}

.list-enter-active {
	transition: opacity 0.5s ease;
}

.list-enter {
	opacity: 0;
}

.list-enter-to {
	opacity: 1;
}

.section {
	margin-bottom: 60px;
	position: relative;
	z-index: 3;

	&:nth-of-type(1) .section-heading {
		color: #fff;
		margin-top: 0;

		@media (max-width: $size-tablet) {
			color: inherit;
		}
	}

	&:nth-of-type(n + 2) {
		opacity: 0;
	}

	&.loaded:nth-of-type(n + 2) {
		opacity: 1;
	}

	&.placeholder {
		&.loading {
			position: absolute;
			transition: opacity 0;
			opacity: 1;
			top: 0;
			left: 0;
			right: 0;
			margin: 0;
			z-index: 1;
		}

		&.loaded {
			transition: opacity 0.4s;
			opacity: 0;

			& /deep/ .media-card {
				.cover,
				.block {
					&::before {
						animation: none;
					}
				}
			}
		}

		.section-heading {
			margin-top: 0;
			opacity: 0;
		}

		@media (max-width: $size-tablet) {
			margin-top: 70px !important; // Offset mobile filters
		}
	}

	@media (max-width: $size-tablet) {
		margin-bottom: 40px;
	}
}

.section-heading {
	display: grid;
	font-size: 2.4rem;
	font-weight: 700;
	grid-column-gap: 50px;
	grid-template-columns: repeat(3, minmax(390px, 460px));
	justify-content: center;
	text-transform: uppercase;
	letter-spacing: 0.1rem;
	user-select: none;

	@media (min-width: $size-desktop-uhd) {
		grid-template-columns: repeat(4, minmax(390px, 460px));
	}

	@media (max-width: $size-desktop-uhd) {
		.uhd {
			display: none;
		}
	}

	@media (max-width: $size-desktop-hd) {
		grid-column-gap: 20px;
	}

	@media (max-width: $size-desktop) {
		grid-template-columns: 1fr 0 1fr;
	}

	@media (max-width: $size-tablet) {
		grid-template-columns: 1fr 0 0;
		font-size: 2rem;
		margin-bottom: 10px;
	}

	@media (max-width: $size-tablet) {
		margin-left: 10px;
	}
}

.shared-chart-notice {
	background: hsl(204, 45%, 98%);
	border-radius: 4px;
	border: solid 2px hsl(201, 100%, 78%);
	bottom: 60px;
	box-shadow: 0 4px 6px shadow(shadow, 0.05), 0 5px 20px shadow(shadow, 0.08);
	color: #103d55;
	cursor: pointer;
	font-size: 1.7rem;
	font-weight: 700;
	left: 60px;
	padding: 20px 30px;
	padding-right: 50px;
	position: fixed;
	z-index: 100;

	> div {
		color: color(blue);
		font-size: 1.6rem;
		font-weight: 500;
		margin-top: 5px;
		text-align: center;
	}
}

.empty-chart {
	font-size: 3rem;
	font-weight: 600;
	margin-top: 120px;
	text-align: center;
}

.mugen-scroll {
	bottom: 0;
	height: 1200px;
	position: absolute;
	z-index: -10;
}
</style>
