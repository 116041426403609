<template>
	<div>
		<div class="episode" v-if="isImageChart">{{ imageEpisodeString }}</div>
		<div class="episode" v-else>{{ episodeString }}</div>
		<div class="countdown" v-if="airingCountdown && !isImageChart">{{ airingCountdown }}</div>
		<div class="date" v-else>{{ startDate }}</div>
	</div>
</template>

<script>
import { pluralize, getMonth } from "@/util";
import { mapGetters } from "vuex";

export default {
	props: {
		media: {
			type: Object,
			required: true
		}
	},
	computed: {
		...mapGetters(["getTime", "isImageChart"]),
		startDate() {
			const { year, month, day } = this.media.startDate;
			if (year && month && day) return `${getMonth(month)} ${day}, ${year}`;
			if (year && month) return `${getMonth(month)} ${year}`;
			if (year) return year;
			return "TBA";
		},
		nextEpisode() {
			return this.media.airingSchedule.nodes.find(node => node.airingAt > this.getTime);
		},
		episodeString() {
			if (this.$route.name === "airing") {
				return this.airingTime.episode;
			}

			const nextEpisode = this.nextEpisode;
			const schedule = nextEpisode ? this.timeUntil(nextEpisode) : {};

			if (nextEpisode && schedule.days < 30) {
				let episodeString = `Ep ${nextEpisode.episode}`;

				if (this.media.episodes) {
					episodeString += ` of ${this.media.episodes}`;
				}

				return `${episodeString} airing in`;
			}

			const { year, month, day } = this.media.startDate;
			const airingOnTense = day ? "on" : "in";
			const airingDate = new Date(year, month ? month - 1 : null, day || null).getTime();
			const now = new Date().getTime();
			const airingTense = month === null || airingDate > now ? "Airing " : "Aired ";
			const isTba = this.startDate === "TBA";

			if (this.media.episodes) {
				let episodeStirng = pluralize(this.media.episodes, "Episode");
				if (isTba) return episodeStirng;
				return `${episodeStirng} ${airingTense.toLowerCase()} ${airingOnTense}`;
			}

			if (isTba) {
				return null;
			}

			return `${airingTense} ${airingOnTense}`;
		},
		imageEpisodeString() {
			const episodes = this.media.episodes;
			return episodes ? pluralize(episodes, "Episode") : null;
		},
		airingCountdown() {
			if (this.$route.name === "airing") {
				return this.airingTime.time;
			}

			const nextEpisode = this.nextEpisode;
			if (!nextEpisode) return;

			const schedule = this.timeUntil(nextEpisode);
			if (schedule.days > 30) {
				return null;
			}

			let countdown = [];
			if (schedule.days !== 0) countdown.push(pluralize(schedule.days, "day"));
			if (schedule.hours !== 0) countdown.push(pluralize(schedule.hours, "hour"));
			if (schedule.minutes !== 0 && (schedule.days === 0 || schedule.hours === 0))
				countdown.push(pluralize(schedule.minutes, "min"));

			return countdown.join(", ");
		},
		airingTime() {
			const timeFormatOptions = {
				hour: "2-digit",
				minute: "2-digit"
			};
			const schedule = this.media.airingSchedule.nodes[0];
			const pastAiring = this.getTime > schedule.airingAt;
			const time = new Intl.DateTimeFormat("en-US", timeFormatOptions).format(
				schedule.airingAt * 1000
			);

			return {
				episode: `Ep ${schedule.episode} ${pastAiring ? "aired" : "airing"} at`,
				time
			};
		}
	},
	methods: {
		timeUntil(nextEpisode) {
			const delta = nextEpisode.airingAt - this.getTime;
			return {
				seconds: delta % 60,
				minutes: Math.trunc(delta / 60) % 60,
				hours: Math.trunc(delta / 60 / 60) % 24,
				days: Math.trunc(delta / 60 / 60 / 24)
			};
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.episode {
	color: color(text-light);
	font-size: 1.2rem;
	font-weight: 600;
}

.date,
.countdown {
	font-size: 1.8rem;
	font-weight: 600;
	padding-top: 3px;

	@media (max-width: $size-mobile) {
		font-size: 1.5rem;
	}
}
</style>
