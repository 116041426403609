<template>
	<div class="tooltip">
		<slot></slot> <span class="tooltip-text">{{ text }}</span>
	</div>
</template>

<script>
export default {
	props: {
		text: {
			type: String,
			required: true
		}
	}
};
</script>

<style lang="scss" scoped>
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip-text {
	background-color: hsl(237, 12%, 30%);
	border-radius: 4px;
	color: #fff;
	font-size: 11px;
	font-weight: 700;
	left: 50%;
	letter-spacing: 0.05em;
	margin-left: -45px; // Half of width
	opacity: 0;
	padding: 5px 0;
	pointer-events: none;
	position: absolute;
	text-align: center;
	text-transform: none;
	top: calc(100% + 6px);
	transition: opacity 75ms ease-in-out;
	width: 90px;
	z-index: 1;
}

.tooltip:hover .tooltip-text {
	opacity: 1;
	animation: 125ms in;
}

@keyframes in {
	0% {
		opacity: 0;
		transform: scale(0.8);
	}
	60% {
		opacity: 1;
	}
	100% {
		transform: none;
	}
}
</style>
