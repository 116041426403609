<template>
	<div
		class="highlighter"
		:class="{ shared: sharedChart, active: highlight }"
		:style="styles"
		@mouseleave="closeMenu(true);"
	>
		<icon
			:name="icon"
			@click="openMenu"
			@mouseover.native="openMenu(true);"
			:class="{ active: highlight, animate }"
			class="icon"
			width="26"
			aria-label="Add HighLight"
		/>

		<transition name="slide-fade-up">
			<div class="dropdown-menu" v-if="showMenu && !sharedChart" v-on-clickaway="closeMenu">
				<template v-if="authenticated">
					<div class="item watching" @click="updateListEntry('CURRENT');">
						<icon name="check-circle" class="menu-icon" width="16" /> Add to Watching list
					</div>
					<div class="item maybe-watching" @click="updateListEntry('PLANNING');">
						<icon name="question-circle" class="menu-icon" width="16" /> Add to Planning list
					</div>
					<div class="divider" />
				</template>
				<div class="item watching" @click="setHighlight('green');">
					<icon name="check-circle" class="menu-icon" width="16" /> Watching
				</div>
				<div class="item maybe-watching" @click="setHighlight('yellow');">
					<icon name="question-circle" class="menu-icon" width="16" /> Maybe Watching
				</div>
				<div class="item not-watching" @click="setHighlight('red');">
					<icon name="cross-circle" class="menu-icon" width="16" /> Not Watching
				</div>
				<template v-if="highlight">
					<div class="divider" />
					<div class="item clear-watching" @click="clearHighlight();">
						<div />
						Clear
					</div>
				</template>
			</div>
		</transition>
	</div>
</template>

<script>
import { mapState } from "vuex";
import { set, get, del } from "idb-keyval";
import { mixin as clickaway } from "vue-clickaway";
import { highlightStore } from "@/main";
import { sleep } from "@/util";
import Settings from "@/util/settings";

export default {
	props: {
		id: {
			type: Number,
			required: true
		}
	},
	mixins: [clickaway],
	data() {
		return {
			highlight: null,
			animate: false,
			showMenu: false,
			sharedColorMap: {
				g: "green",
				y: "yellow",
				r: "red"
			},
			icons: {
				green: "check-circle",
				yellow: "question-circle",
				red: "cross-circle"
			}
		};
	},
	computed: {
		...mapState(["screen"]),
		sharedChart() {
			return this.$store.state.sharedChart;
		},
		icon() {
			if (!this.highlight) {
				return "plus-circle";
			}
			return this.icons[this.highlight];
		},
		styles() {
			if (!this.highlight) return;
			return {
				"--highlight-color": `rgb(var(--color-${this.highlight}))`
			};
		},
		authenticated() {
			return this.$store.state.auth.user.id;
		}
	},
	methods: {
		openMenu(hover = false) {
			if (hover & this.screen.isMobile) {
				return;
			}
			this.showMenu = true;
		},
		closeMenu(hover = false) {
			if (hover & this.screen.isMobile) {
				return;
			}
			this.showMenu = false;
		},
		setHighlight(color) {
			set(this.id, color, highlightStore);
			this.doAnimations();
			this.highlight = color;
			this.shouldHide(color);
			this.syncHighlight(color);
			this.closeMenu();
		},
		clearHighlight() {
			del(this.id, highlightStore);
			this.doAnimations(300);
			this.highlight = null;
			this.syncHighlight(null);
			this.closeMenu();
		},
		syncHighlight(color) {
			const highlight = {
				mediaId: this.id,
				highlight: color
			};
			this.$store.dispatch("updateHighlights", {
				highlights: [highlight]
			});
		},
		updateListEntry(status) {
			this.$store.dispatch("updateListEntry", {
				mediaId: this.id,
				status
			});
			this.closeMenu();
		},
		shouldHide(highlight) {
			if (highlight === "red" && Settings.hideNotWatching) {
				this.$emit("hide-card");
			}
		},
		async doAnimations(duration = 650) {
			this.animate = true;
			await sleep(duration + 50);
			this.animate = false;
		}
	},
	async beforeMount() {
		let highlight;
		if (this.sharedChart) {
			highlight = this.sharedColorMap[this.$route.query[this.id]] || undefined;
		} else {
			highlight = await get(this.id, highlightStore);
			this.shouldHide(highlight);
		}

		if (highlight) {
			this.highlight = highlight;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.highlighter {
	cursor: pointer;
	user-select: none;
}

.shared {
	cursor: default;
}

.icon {
	&.active {
		fill: var(--highlight-color);
		stroke: color(white);

		&.animate {
			animation: bounceIn 650ms;
		}

		& /deep/ circle {
			stroke: var(--highlight-color);
		}
	}

	&:not(.active).animate {
		animation: zoomIn 300ms;
	}

	@media (max-width: $size-tablet) {
		width: 30px !important;
	}
}

.dropdown-menu {
	bottom: 40px;
	font-size: 1.3rem;
	padding: 5px 0;
	right: 10px;
	overflow: visible;

	&:after {
		background: transparent;
		bottom: -20px;
		content: "";
		display: block;
		height: 20px;
		left: 0;
		position: absolute;
		width: 100%;
	}

	.item {
		align-items: center;
		display: grid;
		grid-column-gap: 10px;
		grid-template-columns: 16px auto;
		line-height: 0;
		padding: 8px 18px;
		width: 100%;
	}

	.divider {
		border-bottom: solid 1px color(text-lighter, 0.2);
		margin: 1px 12px 3px;
	}
}

.menu-icon {
	background: color(foreground);
	border-radius: 50%;
	overflow: hidden;
}

.watching .menu-icon {
	fill: color(green);
}

.maybe-watching .menu-icon {
	fill: color(yellow);
}

.not-watching .menu-icon {
	fill: color(red);
}

.dropdown-menu .clear-watching {
	height: 32px;
}
</style>
