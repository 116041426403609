<template>
	<div class="media-card">
		<div class="cover" />
		<div class="data">
			<div class="block big" />

			<div class="block small" />
			<div class="block small" />
			<div class="block small" style="width: 60%" />
			<div class="block small" style="width: 40%" />
		</div>
	</div>
</template>

<script>
export default {
	name: "LoadingCard"
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.media-card {
	backface-visibility: hidden;
	background: color(foreground);
	border-radius: 3px;
	box-shadow: 0 5px 20px shadow(shadow, 0.08);
	display: inline-grid;
	grid-template-columns: 185px auto;
	height: 265px;
	min-width: 370px;
	overflow: hidden;
	position: relative;
	text-align: left;

	@media (max-width: $size-tablet) {
		grid-template-columns: 175px auto;
		min-width: 330px;
		width: 100%;
	}

	@media (max-width: $size-mobile) {
		grid-template-columns: 150px auto;
		height: 230px;
		min-width: 320px;
	}
}

@keyframes loading-pulse {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(50%);
	}
}

.cover,
.block {
	&::before {
		animation: loading-pulse 2s linear infinite;
		background: linear-gradient(
			to right,
			color(background, 0) 0%,
			color(blue-dim, 0.15) 40%,
			color(blue-dim, 0.15) 60%,
			color(background, 0)
		);
		content: "";
		display: block;
		height: 100%;
		transform: translateX(0);
		width: 200%;
	}

	background: color(background, 0.8);
	border-radius: 3px;
	overflow: hidden;
}

.block {
	height: 20px;
	margin: 14px 20px;
	border-radius: 2px;

	&.big {
		height: 30px;
		margin: 22px 20px;
	}

	&.small {
		height: 8px;
		margin-bottom: 0px;
		width: 80%;
	}
}
</style>
