<template>
	<div class="icon-stats">
		<div class="icon-stat" v-if="media.averageScore">
			<icon
				:name="scoreIcon.name"
				:color="`rgb(var(--color-${scoreIcon.color}))`"
				width="18"
				:fill="true"
			/>
			<span class="stat">{{ media.averageScore }}%</span>
		</div>
		<div class="icon-stat" v-if="ranking">
			<icon name="heart" color="rgb(var(--color-red))" width="18" />
			<span class="stat">#{{ ranking.rank }}</span>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		media: {
			type: Object,
			required: true
		}
	},
	computed: {
		ranking() {
			return this.media.rankings.find(ranking => {
				return ranking.type === "POPULAR" && ranking.allTime === false && ranking.season !== null;
			});
		},
		scoreIcon() {
			const scale = [
				{
					name: "face-smile",
					color: "green"
				},
				{
					name: "face-meh",
					color: "orange"
				},
				{
					name: "face-sad",
					color: "red"
				}
			];

			const score = this.media.averageScore;
			if (score > 74) {
				return scale[0];
			}
			if (score > 60) {
				return scale[1];
			}
			return scale[2];
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.icons-stats {
	align-items: flex-end;
	display: flex;
	flex-direction: column;
}

.icon-stat {
	padding-bottom: 10px;
	line-height: 0;

	.stat {
		color: color(text-light);
		font-size: 1.3rem;
		font-weight: 700;
		min-width: 35px;
		padding-left: 8px;
		display: inline-block;
	}
}
</style>
