<template>
	<div class="filters">
		<template v-if="!airingView">
			<tooltip text="Share">
				<icon class="icon" name="share" height="24px" @click="openShare" v-if="!hideShare" />
			</tooltip>
			<tooltip text="Chart Image">
				<icon class="icon" name="screenshot" height="24px" @click="openImage" v-if="!hideShare" />
			</tooltip>
			<tooltip text="Search">
				<icon class="icon" name="search" height="24px" @click="openSearch" />
			</tooltip>
			<tooltip text="Sort">
				<icon
					class="icon"
					name="sort"
					height="24px"
					color="currentColor"
					:fill="true"
					@click="openSort"
				/>
			</tooltip>
		</template>
		<template v-else>
			<tooltip text="Change View">
				<icon class="icon" name="grid" height="24px" @click="toggleAiringView" />
			</tooltip>
		</template>

		<transition name="slide-fade-left">
			<div class="search" v-if="searchVisible" v-on-clickaway="closeMenus">
				<input class="input" ref="search" v-model="search" placeholder="Filter Anime" />
			</div>
		</transition>

		<transition name="slide-fade-down">
			<div class="dropdown-menu sort" v-if="sortMenuVisible" v-on-clickaway="closeMenus">
				<div
					class="item"
					v-for="(label, sortOption) in sorts"
					:key="sortOption"
					@click="sort = sortOption;"
					:class="{ active: sortOption === sort }"
				>
					{{ label }}
				</div>
			</div>
		</transition>

		<transition name="slide-fade-down">
			<share-dialog
				v-if="shareMenuVisible"
				v-on-clickaway="closeMenus"
				:highlights="highlightParams"
			/>
			<image-dialog
				v-if="imageMenuVisible"
				v-on-clickaway="closeMenus"
				:highlights="highlightParams"
			/>
		</transition>
	</div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import copy from "clipboard-copy";
import { get } from "idb-keyval";
import { highlightStore } from "@/main";
import ShareDialog from "./ShareDialog";
import ImageDialog from "./ImageDialog";
import Tooltip from "./Tooltip";
import Settings from "@/util/settings";

export default {
	name: "Filters",
	mixins: [clickaway],
	components: {
		ShareDialog,
		ImageDialog,
		Tooltip
	},
	data() {
		return {
			searchVisible: false,
			sortMenuVisible: false,
			shareMenuVisible: false,
			imageMenuVisible: false,
			highlightParams: null,
			sorts: {
				title: "Title",
				nextAiring: "Next Airing Episode",
				popularity: "Popularity",
				averageScore: "Score",
				studio: "Studio",
				startDate: "Start Date",
				endDate: "End Date",
				dateAdded: "Date Added"
			}
		};
	},
	computed: {
		search: {
			get() {
				return this.$store.state.filter;
			},
			set(filter) {
				this.$store.commit("setFilter", filter);
			}
		},
		sort: {
			get() {
				return this.$store.state.sort;
			},
			set(sort) {
				this.$store.commit("setSort", sort);
				this.closeMenus();
			}
		},
		hideShare() {
			return this.$route.name !== "season";
		},
		airingView() {
			return this.$route.name === "airing";
		}
	},
	methods: {
		async getHighlightParams() {
			let highlightParams = "";
			const highlightShort = {
				green: "g",
				yellow: "y",
				red: "r"
			};
			const chartKey = this.$store.state.currentChart;
			const mediaIds = this.$store.getters.chart(chartKey);

			for (let i = 0; i < mediaIds.length; i++) {
				const highlight = await get(mediaIds[i], highlightStore);
				if (highlight) {
					highlightParams += `&${mediaIds[i]}=${highlightShort[highlight]}`;
				}
			}

			return highlightParams === "" ? null : highlightParams;
		},
		async openSearch() {
			this.searchVisible = true;
			await this.$nextTick();
			if (this.$refs.search) {
				this.$refs.search.focus();
			}
		},
		openSort() {
			this.sortMenuVisible = true;
		},
		async openImage() {
			this.imageMenuVisible = true;
			this.highlightParams = await this.getHighlightParams();
		},
		async openShare() {
			this.shareMenuVisible = true;
			this.highlightParams = await this.getHighlightParams();
		},
		toggleAiringView() {
			const currentView = Settings.airingView;
			this.$store.commit("setAiringView", currentView === "chart" ? "calendar" : "chart");
		},
		closeMenus() {
			this.searchVisible = false;
			this.sortMenuVisible = false;
			this.imageMenuVisible = false;
			this.shareMenuVisible = false;
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.filters {
	color: #777a9e;
	letter-spacing: initial;
	position: relative;
	text-align: right;
}

.tooltip {
	cursor: pointer;
	margin-left: 20px;
}

.sort {
	right: 0;
	margin-top: 14px;

	.item {
		font-size: 1.3rem;
	}
}

.image-chart {
	width: 360px;
	right: -3vw;

	.select {
		grid-template-columns: repeat(4, 1fr);
	}

	.link-copied {
		bottom: initial;
		top: 50px;
		left: 110px;
	}
}

.search {
	position: absolute;
	right: 75px;
	top: -0;
	z-index: 40;
}
</style>

<style lang="scss">
@import "~@/styles/variables.scss";

.dialog {
	.title {
		color: color(blue);
		font-size: 1.7rem;
		margin: 0;
		padding-bottom: 2px;
	}

	.description {
		color: color(text-light);
		font-size: 1.2rem;
		padding-bottom: 20px;
		font-weight: 600;
	}

	.select {
		align-items: center;
		background: color(background, 0.8);
		border-radius: 3px;
		display: grid;
		justify-items: center;
		overflow: hidden;
	}

	.option {
		align-items: center;
		cursor: pointer;
		display: flex;
		height: 40px;
		justify-content: center;
		margin: 0;
		width: 100%;
		transition: color 200ms, background-color 200ms;

		&:hover {
			color: color(white);
			background-color: color(blue);
		}
	}

	.btn {
		align-items: center;
		background: color(background, 0.8);
		border-radius: 3px;
		cursor: pointer;
		display: flex;
		font-size: 1.4rem;
		height: 38px;
		justify-content: center;
		transition: color 200ms, background-color 200ms;
		width: 100%;

		&:hover {
			color: color(white);
			background-color: color(blue);
		}
	}

	.link-copied {
		background: color(overlay, 0.8);
		border-radius: 3px;
		color: color(white);
		opacity: 0;
		padding: 4px 8px;
		position: absolute;
		transition: opacity 200ms ease-in-out;

		&.active {
			opacity: 1;
		}
	}
}
</style>
