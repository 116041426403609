<template>
	<div class="filters">
		<input class="input" v-model="search" placeholder="Filter Anime" />
		<icon
			class="icon"
			name="sort"
			height="20px"
			color="currentColor"
			:fill="true"
			@click="openSort"
		/>

		<transition name="slide-fade-down">
			<div
				class="dropdown-menu dropdown-menu-mobile"
				v-if="sortMenuVisible"
				v-on-clickaway="closeMenus"
			>
				<div
					class="item"
					v-for="(label, sortOption) in sorts"
					:key="sortOption"
					@click="sort = sortOption;"
					:class="{ active: sortOption === sort }"
				>
					{{ label }}
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import { mixin as clickaway } from "vue-clickaway";
import Filters from "./Filters";

export default {
	name: "MobileFilters",
	extends: Filters
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.filters {
	align-items: center;
	color: #777a9e;
	display: grid;
	grid-template-columns: auto 42px;
	justify-items: center;
	letter-spacing: initial;
	margin-bottom: 30px;
	margin-top: 10px;
	position: relative;
	z-index: 80;
}

.input {
	width: 100%;
}

.dropdown-menu {
	font-size: 1.4rem;
	right: 0;
	top: 50px;
}

.item {
	padding: 10px 20px;
}
</style>
