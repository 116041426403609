<template>
	<div class="dialog dropdown-menu image-chart">
		<h2 class="title">Seasonal Chart Images</h2>
		<div class="description">These images are automatically updated every hour</div>
		<div class="select">
			<div class="option" @click="copyImageUrl('Winter');">Winter</div>
			<div class="option" @click="copyImageUrl('Spring');">Spring</div>
			<div class="option" @click="copyImageUrl('Summer');">Summer</div>
			<div class="option" @click="copyImageUrl('Fall');">Fall</div>
		</div>
		<div class="link-copied" :class="{ active: linkCopied }">Link copied to clipboard</div>

		<h2 class="title">Generate Chart Image</h2>
		<div class="description">Generate an image of this chart with your highlights</div>
		<div class="btn" @click="generateImage">{{ generationStatus }}</div>
	</div>
</template>

<script>
import copy from "clipboard-copy";

export default {
	props: ["highlights"],
	data() {
		return {
			generationStatus: "Generate",
			linkCopied: false
		};
	},
	methods: {
		async copyImageUrl(imageName, url) {
			if (!url) {
				url = `https://image.anichart.net/i/${imageName}.jpg`;
			}

			await copy(url);
			this.linkCopied = true;
			setTimeout(() => {
				this.linkCopied = false;
			}, 2500);
			this.link = url;
		},
		async generateImage() {
			if (this.generationStatus !== "Generate") return;
			this.generationStatus = "Generating image...";

			const [season, year] = this.$route.path.replace("/", "").split("-");
			if (!this.highlights) {
				this.copyImageUrl(season);
				return;
			}

			const url = "https://image.anichart.net/screenshot";
			try {
				const response = await fetch(url, {
					method: "post",
					headers: new Headers({
						"Content-Type": "application/json"
					}),
					body: JSON.stringify({
						season,
						year,
						highlights: this.highlights
					})
				});
				const responseData = await response.json();
				if (!response.ok) {
					throw responseData;
					return;
				}

				this.copyImageUrl(null, responseData.url);
				this.generationStatus = "Image Generated. URL copied to clipboard";
			} catch (error) {
				this.generationStatus = "Generate";
				this.$store.dispatch("notify", {
					header: "Image Generation Error",
					text: error.message,
					type: "error"
				});
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";

.image-chart {
	margin-top: 14px;
	padding-top: 14px;
	padding: 18px;
	right: -3vw;
	width: 360px;

	.select {
		grid-template-columns: repeat(4, 1fr);
		margin-bottom: 20px;
	}

	.link-copied {
		top: 45px;
		left: 100px;
	}
}
</style>
